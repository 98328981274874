export default class TeamApiService {
  // Init
  constructor() {
    this.prefix = '/api/team/v1';
    this.v2Prefix = '/api/team/v2';
  }

  // Helpers
  loadAllPages(url, key, data = []) {
    const vm = this;
    const getPage = function (url) {
      return axios.get(url)
        .then((response) => {
          data.push.apply(data, response.data[key]);
          const nextPageUrl = vm.nextPageUrl(response.headers);
          if (nextPageUrl) {
            return getPage(nextPageUrl);
          }
          return data;
        })
        .catch((error) => {
          throw error;
        });
    };
    return getPage(url);
  }

  nextPageUrl(headers) {
    const vm = this;
    if (!headers || !headers.link) {
      return null;
    }

    const paginationLinks = headers.link.split(',');
    let nextPageUrl = null;

    paginationLinks.forEach((l) => {
      const nextRel = 'rel="next"';
      if (l.indexOf(nextRel) !== -1) {
        const regExp = /\<([^>]+)>/;
        nextPageUrl = regExp.exec(l)[1];
      }
    });

    return nextPageUrl;
  }

  // Routes
  athleteIdsUrl() {
    return `${this.prefix}/athlete_identifiers`;
  }

  checkInUrl() {
    return `${this.prefix}/check_ins`;
  }

  genericCheckInsUrl(date) {
    return `${this.prefix}/check_ins?for_day=${date.toISOString()}&type=generic`;
  }

  contributionUrl(fundraiserSlug) {
    return `${this.prefix}/fundraisers/${fundraiserSlug}/contributions`;
  }

  previewContributionV2Url(fundraiserSlug) {
    return `${this.v2Prefix}/fundraisers/${fundraiserSlug}/contributions/preview`;
  }

  contributionV2Url(fundraiserSlug) {
    return `${this.v2Prefix}/fundraisers/${fundraiserSlug}/contributions`;
  }

  orderUrl(storeSlug) {
    return `${this.prefix}/stores/${storeSlug}/orders`;
  }

  previewOrderUrl(storeSlug) {
    return `${this.prefix}/stores/${storeSlug}/orders/preview`;
  }

  donationUrl() {
    return `${this.prefix}/donations`;
  }

  previewDonationUrl() {
    return `${this.prefix}/donations/preview`;
  }

  customerUrl() {
    return `${this.prefix}/customers`;
  }

  eventsUrl(params) {
    return `/api/team/v1/events${params}`;
  }

  eventCheckInsUrl(eventId, status) {
    return `/api/team/v1/events/${eventId}/check_ins?status=${status}`;
  }

  fundraisers() {
    return '/api/team/v1/fundraisers';
  }
  fundraiserPlansUrl(fundraiserSlug) {
    return `${this.prefix}/fundraisers/${fundraiserSlug}/plans`;
  }

  kioskSessionUrl() {
    return `${this.prefix}/kiosk_sessions`;
  }

  payInvoiceUrl(token) {
    return `${this.prefix}/manual_invoices/${token}/payment`;
  }

  registrationAnswers(profileId, profileType, checkInId, sessionParam) {
    let url = `${this.prefix}/registration_answers?profile_id=${profileId}&profile_type=${profileType}`;
    if (checkInId) {
      url = `${url}&check_in_id=${checkInId}`;
    }

    return url;
  }

  registrationAnswersUrl() {
    return `${this.prefix}/registration_answers/batch`;
  }

  registrationQuestions(profileType, session_param) {
    let url = null;

    if (profileType === 'ParentProfile') {
      url = `${this.prefix}/registration_questions?for_type=parents`;
    } else if (profileType === 'WrestlerProfile') {
      url = `${this.prefix}/registration_questions?for_type=wrestlers`;
    } else if (profileType === 'CoachProfile') {
      url = `${this.prefix}/registration_questions?for_type=coaches`;
    } else if (profileType === 'CheckIn') {
      url = `${this.prefix}/registration_questions?for_type=check_ins`;
    }

    if (url && session_param) {
      url = `${url}&paid_session=${session_param}`;
    }

    return url;
  }

  rostersUrl() {
    return `${this.prefix}/rosters`;
  }

  teamUrl(teamId) {
    return `${this.prefix}/teams/${teamId}`
  }

  wrestlersUrl() {
    return `${this.prefix}/wrestlers`;
  }
}
