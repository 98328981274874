<template>
  <div class="container">
    <template v-if="needToAuth">
      <device-setup :store="store"></device-setup>
    </template>
    <template v-else-if="booting">
      <div class="row m-t-lg">
        <div class="col-xs-12 col-sm-6 col-sm-offset-3 col-md-4 col-md-offset-4">
          <div class="ibox">
            <div class="ibox-content p-m text-center">
              <div class="sk-spinner sk-spinner-fading-circle">
                <div class="sk-circle1 sk-circle"></div>
                <div class="sk-circle2 sk-circle"></div>
                <div class="sk-circle3 sk-circle"></div>
                <div class="sk-circle4 sk-circle"></div>
                <div class="sk-circle5 sk-circle"></div>
                <div class="sk-circle6 sk-circle"></div>
                <div class="sk-circle7 sk-circle"></div>
                <div class="sk-circle8 sk-circle"></div>
                <div class="sk-circle9 sk-circle"></div>
                <div class="sk-circle10 sk-circle"></div>
                <div class="sk-circle11 sk-circle"></div>
                <div class="sk-circle12 sk-circle"></div>
              </div>
              <h1 class="m-t-xl">Launching WrestlingIQ Kiosk</h1>
              <p class="text-danger m-t-sm" v-if="bootError">
                Unable to launch kiosk. Please double check your internet connection and refresh the page.<br/><br/>
                Contact support@wrestlingiq.com if the issue persists.
              </p>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="row">
        <div class="col-xs-12 col-sm-6 col-sm-push-6">
          <div class="ibox" v-if="this.store.state.eventToCheckIn">
            <div class="flex justify-space-between p-m text-green-50 bg-green-600 rounded-t-xxs border-b border-solid border-green-50">
              <h1 class="m-y-none">
                <span v-if="!checkingInGenerically">
                  Check into {{ store.state.eventToCheckIn.name }}
                </span>
                <span v-else>
                  Check in
                </span>
              </h1>
            </div>
            <div class="ibox-content p-t-sm">
              <search id="search-form" class="search-form m-t-sm"
                      :display-all-on-empty="false"
                      query-key="query"
                      response-key="wrestlers"
                      placeholder="Search by wrestler name..."
                      :include-guests="store.state.searchGuests"
                      :roster-ids="hasRosterFilter ? store.state.rostersToFilter : null"
                      :base-url="wrestlersUrl()">
                <template v-slot:list="slotProps">
                  <wrestler-kiosk-result
                      :wrestler="slotProps.result"
                      :key="slotProps.result.id"
                      v-on:check-in="processCheckIn"
                  >
                  </wrestler-kiosk-result>
                </template>
                <template v-slot:nothingfound>
                  <div v-if="hasRosterFilter" class="p-x-sm m-t-xs">
                    <p>
                      Wrestler not found in <strong>{{ rosterFilterName }}</strong>. Please double check that payments
                      are in good standing or remove the roster filter to check them in.
                    </p>
                  </div>
                </template>
              </search>
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-sm-6 col-sm-pull-6">
          <div class="ibox p-t-xxs">
            <div class="ibox-content">
              <div v-if="!this.store.state.eventToCheckIn">
                <!-- We do not have an event to check in explicitly set -->
                <div class="m-t-m m-l-sm">
                  <p class="m-t-none m-b-none uppercase">
                    {{ store.state.teamInfo.name }}
                  </p>
                  <h1 class="m-t-xxs">
                    What event are you checking in for?
                  </h1>
                </div>
                <fieldset class="m-t-m">
                  <legend class="sr-only">
                    Event to check in wrestlers to
                  </legend>
                  <div>
                    <label
                        class="relative border border-top p-m cursor-pointer flex justify-space-between align-items-center"
                        @click="store.setEventToCheckIn(store.state.genericEvent)">
                      <div>
                        <p class="font-normal text-16 m-b-xs">Generic check-in</p>
                        <p class="font-normal m-b-xxs"><small>Track facility access, rather than attendance at a
                          specific event.</small></p>
                      </div>
                      <div class="m-l-m">
                        <i class="fa fa-caret-right"></i>
                      </div>
                    </label>
                    <label v-for="(e, index) in store.state.upcomingEvents"
                           class="relative block border border-top p-m cursor-pointer flex justify-space-between align-items-center"
                           :class="{ 'border-bottom': store.state.upcomingEvents.length - 1 === index }"
                           @click="store.setEventToCheckIn(e)">
                      <div>
                        <p class="font-normal text-16 m-b-xs"> {{ e.name }}</p>
                        <p class="font-normal m-b-xxs"><small>{{ eventTime(e) }}</small></p>
                      </div>
                      <div class="m-l-m">
                        <i class="fa fa-caret-right"></i>
                      </div>
                    </label>
                  </div>
                </fieldset>
                <p class="p-w-m m-t-m"><small class="color-gray-400">Don't see the event you are looking for? Refresh
                  the page to load today's events.</small></p>
              </div>
              <div v-else>
                <div class="flex align-items-baseline m-t-m">
                  <div>
                    <div class="m-t-none">
                      <p class="m-t-none m-b-none uppercase">
                        {{ store.state.teamInfo.name }}
                      </p>
                      <h1 class="m-t-xxs m-b-xs">
                        <span v-if="checkingInGenerically">Check ins</span>
                        <span v-else>{{ store.state.eventToCheckIn.name }}</span>
                      </h1>
                    </div>
                    <p v-if="checkingInGenerically">
                      No event specified
                    </p>
                    <p v-else>
                      <span class="font-small">{{ eventTime(store.state.eventToCheckIn) }}</span>
                    </p>
                  </div>
                  <div class="m-l-auto flex">
                    <i class="fa fa-calendar-times-o cursor-pointer color-blue-500 m-r-md"
                       @click="store.setEventToCheckIn(null)"></i>
                    <i class="fa fa-gear cursor-pointer color-blue-500"
                       @click="store.state.showConfig = !store.state.showConfig"></i>
                  </div>
                </div>
                <div v-show="store.state.showConfig" class="bg-gray-50 p-m rounded-xs m-b-m" style="position: relative;">
                  <div class="kiosk-gear-triangle">
                  </div>
                  <div class="flex align-items-baseline">
                    <label class="checkbox-inline"
                           :class="{ 'text-gray-200': hasRosterFilter, 'text-strikethrough': hasRosterFilter }">
                      <input type="checkbox" v-model="store.state.searchGuests" :disabled="hasRosterFilter"> Include
                      guest
                      wrestlers
                    </label>
                    <label class="m-l-xs">
                      <VDropdown theme="info-tooltip">
                        <!-- This will be the popover target (for the events and position) -->
                        <i class="fa fa-question"></i>
                        <!-- This will be the content of the popover -->
                        <template v-slot:popper>
                          <div>
                            <div v-if="hasRosterFilter">
                              <p class="header">
                                You must disable the {{ rosterFilterName }} roster filter to enable guest search
                              </p>
                              <p>
                                Guest accounts that are synced with rosters (such as camp registrants), <strong>will still show up</strong>
                                if they are on the roster currently selected.
                              </p>
                            </div>
                            <div v-else>
                              <p class="header">
                                Guest wrestlers
                              </p>
                              <p>
                                Wrestlers who have only signed up for a
                                <a href="https://help.wrestlingiq.com/article/54-whats-the-difference-between-a-full-season-one-time-session-and-other"
                                   target="_blank">
                                  'one time session' event
                                </a> do not have full WrestlingIQ accounts.
                              </p>
                              <p>
                                These guest accounts will show up in wrestler search if you check this box.
                              </p>
                            </div>
                          </div>
                        </template>
                      </VDropdown>
                    </label>
                  </div>
                  <label class="checkbox-inline" v-if="teamInfoHasUsawRequired">
                    <input type="checkbox" v-model="store.state.forceUsawOff"> Turn off USAW alert
                  </label>
                </div>
                <div class="flex flex-col border-y border-gray-200 border-solid p-h-sm full-width-row bg-gray-50 m-b-m" style="padding-left: 24px; margin-left: -24px; padding-right: 24px; margin-right: -24px;">
                  <form>
                    <div class="flex align-items-center gap-x-m">
                      <h2 class="font-small font-semi m-b-none m-t-none text-gray-500">
                        Filters
                      </h2>
                      <div aria-hidden="true" style="height: 18px; width: 1px;" class="bg-gray-300 block"></div>
                      <button
                          class="btn btn-none flex align-items-center justify-space-between p-w-xs p-l-n text-gray-400 bg-gray-50"
                          @click.prevent="store.state.rosterFiltersOpen = !store.state.rosterFiltersOpen">
                        <span class="text-gray-600">Roster</span>
                        <span
                            class="m-l-sm -m-r-xxs font-normal border border-gray-300 border-solid rounded-xxs p-w-xs font-small text-gray-400"
                            v-if="store.state.rostersToFilter.length > 0">{{ store.state.rostersToFilter.length }}</span>
                        <span class="m-l-xxs flex align-items-center">
                <svg :class="[store.state.rosterFiltersOpen ? 'rotate-180' : 'rotate-0']" xmlns="http://www.w3.org/2000/svg"
                     viewBox="0 0 20 20" fill="currentColor" style="height: 18px; width: 18px;" aria-hidden="true"
                     class="text-gray-400">
                  <path fill-rule="evenodd"
                        d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                        clip-rule="evenodd"/>
                </svg>
          </span>
                      </button>
                    </div>
                    <transition
                        enter-active-class="transition ease-out duration-100"
                        enter-class="opacity-0 translate-y-1"
                        enter-to-class="opacity-0 translate-y-1"
                        leave-active-class="transition ease-in duration-150"
                        leave-class="opacity-100 translate-y-0"
                        leave-to-class="opacity-0 translate-y-1"
                    >
                      <div v-show="store.state.rosterFiltersOpen" class="p-x-sm">
                        <div class="grid grid-cols-2 md-grid-cols-1 gap-y-3 m-t-xxs">
                          <div v-for="(option, optionIdx) in store.state.rosters" :key="option.id"
                               class="flex align-items-center">
                            <input type="checkbox" :id="`filter-${optionIdx}`" v-bind:checked="rosterSelected(option)" @change="addRosterFilter(option, $event)"/>
                            <label :for="`filter-${optionIdx}`" class="m-l-xs text-small text-gray-600 font-normal m-b-none">{{
                                option.name
                              }}</label>
                          </div>
                        </div>
                      </div>
                    </transition>
                  </form>
                  <div class="flex flex-wrap gap-xxs m-t-xs m-b-xs" v-show="selectedRosters.length > 0 || store.state.searchGuests">
                    <button v-for="filter in selectedRosters" :key="filter.id" @click.prevent="removeRosterFilter(filter)"
                            class="btn btn-success btn-outline rounded-lg font-small"
                            style="padding: 2px 6px;">
                      {{ filter.name }}
                      <i class="fa fa-times"></i>
                    </button>
                    <button v-if="store.state.searchGuests && !hasRosterFilter" @click="store.state.searchGuests = false"
                            class="btn btn-success btn-outline rounded-lg font-small" style="padding: 2px 6px;">
                      Guests included <i class="fa fa-times m-l-xxs"></i>
                    </button>
                  </div>
                </div>
                <div v-if="store.state.wrestlersToConfirm.length > 0" class="m-b-lg">
                  <p class="font-small m-t-xxs m-l-xxs m-b-m">
                    <strong>{{ store.state.wrestlersToConfirm.length }}</strong> check ins pending
                  </p>
                  <div class="grid grid-cols-3 gap-4 m-b-xs">
                    <div v-for="confirmObj in store.state.wrestlersToConfirm" class="cursor-pointer flex-col hover-opacity-75 bg-red-50" @click="showCheckInConfirmation(confirmObj)">
                      <div v-if="confirmObj.profile.avatar_thumbnail" class="">
                        <img :src="confirmObj.profile.avatar_thumbnail" class="kiosk-grid-pic" alt="profile picture"/>
                      </div>
                      <profile-photo-placeholder classes="kiosk-grid-pic color-gray-400" v-else></profile-photo-placeholder>
                      <h3 class="m-t-sm m-b-none">
                        {{ confirmObj.profile.display_name }}
                      </h3>
                      <div class="flex align-items-center justify-space-between">
                        <div class="flex m-r-xs">
                          <span class="color-red-700"><i class="fa fa-warning"></i></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <paginated-check-ins
                    :check-ins="this.store.state.checkIns"
                    :events="this.store.state.upcomingEvents"
                    :store="this.store"
                ></paginated-check-ins>
              </div>
            </div>
          </div>
          <div class="ibox p-t-xxs" v-if="this.store.state.checkInQuestions.length > 0">
            <div class="ibox-content">
              <kiosk-form
                  :check-in-questions="this.store.state.checkInQuestions"
                  :store="this.store"
              >
              </kiosk-form>
            </div>
          </div>
        </div>
      </div>
      <div class="footer navbar-fixed-bottom flex">
        <div v-if="store.state.lookingUpId">
          <div class="ibox m-b-none">
            <div class="ibox-content p-t-sm p-b-sm">
              <div class="flex align-items-center justify-space-between">
                <div class="sk-spinner sk-spinner-fading-circle">
                  <div class="sk-circle1 sk-circle"></div>
                  <div class="sk-circle2 sk-circle"></div>
                  <div class="sk-circle3 sk-circle"></div>
                  <div class="sk-circle4 sk-circle"></div>
                  <div class="sk-circle5 sk-circle"></div>
                  <div class="sk-circle6 sk-circle"></div>
                  <div class="sk-circle7 sk-circle"></div>
                  <div class="sk-circle8 sk-circle"></div>
                  <div class="sk-circle9 sk-circle"></div>
                  <div class="sk-circle10 sk-circle"></div>
                  <div class="sk-circle11 sk-circle"></div>
                  <div class="sk-circle12 sk-circle"></div>
                </div>
                <div class="m-l-sm">
                  Searching for scanned id...
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="align-self-flex-end m-l-auto">
          <a @click="logOut" class="text-success text-underline p-m">
            Sign out
          </a>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import axios from 'axios';
import DeviceSetup from './device-setup.vue';
import PaginatedCheckIns from './paginated-check-ins.vue';
import ProfilePhotoPlaceholder from './profile-photo-placeholder.vue';
import Search from '../shared/search.vue';
import swal from 'sweetalert2';
import WrestlerKioskResult from '../shared/wrestler_kiosk_result.vue';
import KioskForm from './kiosk-form.vue';

export default {
  name: 'kiosk-app',
  components: {
    DeviceSetup,
    KioskForm,
    PaginatedCheckIns,
    ProfilePhotoPlaceholder,
    Search,
    WrestlerKioskResult,
  },
  data() {
    return {
      searching: false,
      // Booting screen
      booting: true,
      bootError: false,

      // Simple shared store https://vuejs.org/v2/guide/state-management.html
      // so we can share state in a reactive way with components
      store: {
        debug: false,
        state: {
          // Auth info
          kiosk: null,

          // Team info (branding, name, usaw requirements, etc)
          teamInfo: null,

          // In progress barcode scan
          scan: '',
          lookingUpId: false,

          // Configuration
          searchGuests: false,
          showConfig: false,

          // Events we can check in for
          eventToCheckIn: null,
          genericEvent: {
            id: -1,
          },
          // All rosters
          rosters: [],
          // Rosters to filter by
          rostersToFilter: [],
          rosterFiltersOpen: false,
          upcomingEvents: [],
          // A list of all check ins recorded, regardless of state (used by paginated list of check ins)
          checkIns: [],
          // Custom questions, if they are defined by the team's config
          checkInQuestions: [],
          // There are some check-ins that need to be confirmed: e.g. if their USAW card is expired or their scanned ID card was not found on the roster
          // These take the form of { profile: profile, title: 'title for swal', text: 'text for swal' }
          wrestlersToConfirm: [],
          // The queue of checkIns to finalize, if check in questions are present.
          checkInsToFinalize: [],
          // The check in being currently edited by kiosk-form
          editingCheckIn: null,
          // Are we loading up check ins from the server?
          loadingCheckIns: false,
          // Setting for suppressing usaw alert
          forceUsawOff: false,
        },
        setTeamInfo(team) {
          if (this.debug) console.log('setTeamInfo triggered with', team);
          this.state.teamInfo = team;
        },
        setUpcomingEvents(events) {
          if (this.debug) console.log('setUpComingEvents triggered with', events);
          this.state.upcomingEvents = events;
          const savedEvent = JSON.parse(localStorage.getItem('event_for_checkin'));
          const upcomingEventIds = _.map(events, 'id');
          if (_.includes(upcomingEventIds, savedEvent?.id)) {
            this.setEventToCheckIn(savedEvent);
          }
        },
        setEventToCheckIn(event) {
          if (this.debug) console.log('setEventToCheckIn triggered with', event);
          this.state.eventToCheckIn = event;
        },
        setRostersToFilter(rosterIds) {
          if (this.debug) console.log('setRostersToFilter triggered with', rosterIds);
          this.state.rostersToFilter = _.compact(_.uniq(rosterIds));
        },
        setRosters(rosters) {
          if (this.debug) console.log('setRoster triggered with', rosters);
          this.state.rosters = rosters;
          this.setupSavedFilters();
        },
        setupSavedFilters() {
          const allRosterIds = _.map(this.state.rosters, 'id');
          // Roster filtering
          let filterRosters = [];

          // Temp hack until everyone is transitioned to multiple roster filters, although it won't harm anything to keep it for a long time
          const savedFilter = JSON.parse(localStorage.getItem('roster_to_filter'));
          if (savedFilter && (savedFilter !== -1 || savedFilter !== '-1') && _.includes(allRosterIds, savedFilter)) {
            filterRosters.push(savedFilter);
          }

          // Multi roster filter key
          const savedFilters = JSON.parse(localStorage.getItem('rosters_to_filter'));
          if (savedFilters) {
            savedFilters.forEach((r) => {
              if (_.includes(allRosterIds, r)) {
                filterRosters.push(r)
              }
            });
          }

          this.setRostersToFilter(filterRosters);
        },
        addToCheckIns(checkIn, notificationManager) {
          if (this.debug) console.log('pushing check in', checkIn);
          const existingIdx = _.findIndex(this.state.checkIns, function (ch) {
            return ch.id === checkIn.id;
          });
          if (existingIdx !== -1) {
            // We already had this check in, move it to the front of the array
            this.state.checkIns.unshift(this.state.checkIns.splice(existingIdx, 1)[0]);
            notificationManager.$emit('show-toast', `${checkIn.profile.display_name} is already checked in`, true);
          } else {
            // Just add the checkin
            this.state.checkIns.unshift(checkIn);
            notificationManager.$emit('show-toast', `${checkIn.profile.display_name} checked in`, true);
          }
          // If we have custom questions needed to finalize a check in, push it onto the
          // queue for check ins to be finalizd
          if (this.state.checkInQuestions.length > 0) {
            this.setCheckInToFinalize(checkIn);
          }
        },
        setCheckIns(checkIns) {
          if (this.debug) console.log('setCheckIns triggered with', checkIns);
          this.state.checkIns = checkIns;
        },
        setCheckInQuestions(questions) {
          if (this.debug) console.log('setCheckInQuestions triggered with', questions);
          this.state.checkInQuestions = questions;
        },
        // Barcode scanners trigger enter presses, which means scanning multiple times can actually 'ok' a check-in
        // without the user meaning to
        setWrestlerCheckInToConfirm(wrestler, title, text, allowEnterKey, confirmCallback) {
          if (this.debug) console.log('setWrestlerCheckInToConfirm triggered with', wrestler);
          const existingIdx = _.findIndex(this.state.wrestlersToConfirm, function (ch) {
            return ch.profile.id === wrestler.id;
          });
          if (existingIdx !== -1) {
            // We already had this wrestler, remove the previous one from here so we can deal with the new round of confirmation
            this.state.wrestlersToConfirm.splice(existingIdx, 1);
          }

          this.state.wrestlersToConfirm.push({ profile: wrestler, title: title, text: text, allowEnterKey: allowEnterKey, confirmCallback: confirmCallback });
        },
        removeConfirmObj(confirmObj) {
          if (this.debug) console.log('removeConfirmObj triggered with', confirmObj);
          if (this.debug) console.log('removeConfirmObj wtoconfirm triggered with', this.state.wrestlersToConfirm[0]);

          const existingIdx = _.findIndex(this.state.wrestlersToConfirm, function (ch) {
            return ch.profile.id === confirmObj.profile.id && ch.confirmCallback === confirmObj.confirmCallback;
          });
          if (existingIdx !== -1) {
            this.state.wrestlersToConfirm.splice(existingIdx, 1);
          }
          if (this.debug) console.log('removeConfirmObj wtoconfirm after triggered with', this.state.wrestlersToConfirm);
        },
        setCheckInToFinalize(checkIn) {
          if (this.debug) console.log('setCheckInToFinalize triggered with', checkIn);
          this.state.checkInsToFinalize.push(checkIn);
          // If we aren't busy, dequeue the next check in.
          if (!this.state.editingCheckIn) {
            this.dequeueNextCheckInForFinalization();
          }
        },
        dequeueNextCheckInForFinalization() {
          if (this.debug) console.log('dequeueNextCheckInForFinalization triggered');
          const nextCheckIn = _.head(this.state.checkInsToFinalize);
          this.setEditingCheckIn(nextCheckIn);
        },
        setEditingCheckIn(checkIn) {
          if (this.debug) console.log('setEditingCheckIn triggered with', checkIn);
          this.state.editingCheckIn = checkIn;
        },
        checkInFinalized(checkInId) {
          if (this.debug) console.log('checkInFinalized triggered with', checkInId);
          _.remove(this.state.checkInsToFinalize, (ch) => ch.id === checkInId);
          if (checkInId === this.state.editingCheckIn?.id) {
            this.dequeueNextCheckInForFinalization();
          }
        },
        setKiosk(kiosk) {
          if (kiosk) {
            localStorage.setItem('wiq_kiosk', JSON.stringify(kiosk));
            axios.defaults.headers.common.Authorization = `Bearer ${kiosk.team.api_key}`;
            this.state.kiosk = kiosk;
          } else {
            axios.defaults.headers.common.Authorization = 'Bearer';
            localStorage.removeItem('wiq_kiosk');
            this.state.kiosk = null;
          }
        },
      },
    };
  },
  mounted() {
    // See if we are authed
    try {
      const kiosk = JSON.parse(localStorage.getItem('wiq_kiosk'));
      this.store.setKiosk(kiosk);
      axios.defaults.headers.common.Authorization = `Bearer ${kiosk.team.api_key}`;
    } catch (e) {
      // If we fail, assume this was corrupted client side, clear it out and return
      localStorage.removeItem('wiq_kiosk');
      axios.defaults.headers.common.Authorization = 'Bearer';
    }
  },
  created() {
    this.$notificationManager.$on('searching', this.setSearching);
    window.addEventListener('keydown', this.keydown, true);
  },
  destroyed() {
    this.$notificationManager.$off('searching', this.setSearching);
    window.removeEventListener('keydown', this.keydown, true);
  },
  computed: {
    checkingInGenerically() {
      return !this.store.state.eventToCheckIn || this.store.state.eventToCheckIn.id === -1;
    },
    eventHasRosters() {
      return !this.checkingInGenerically && (Array.isArray(this.store.state.eventToCheckIn.roster_events) &&  this.store.state.eventToCheckIn.roster_events.length > 0);
    },
    needToAuth() {
      return this.store.state.kiosk === null;
    },
    hasRosterFilter() {
      return this.store.state.rostersToFilter.length > 0;
    },
    rosterFilterName() {
      if (!this.hasRosterFilter) {
        return '';
      }
      const rosters = _.filter(this.store.state.rosters, (roster) => {
        return _.includes(this.store.state.rostersToFilter, roster.id);
      });
      return _.join(_.map(rosters, 'name'), ', ');
    },
    selectedRosters() {
      return _.filter(this.store.state.rosters, (roster) => {
        return _.includes(this.store.state.rostersToFilter, roster.id);
      });
    },
    teamInfoHasUsawRequired() {
      return this.store.state.teamInfo && this.store.state.teamInfo.usaw_memberships !== 'usaw_disabled';
    },
    requiresUsaw() {
      return this.teamInfoHasUsawRequired && !this.store.state.forceUsawOff;
    },
  },
  watch: {
    needToAuth() {
      if (!this.needToAuth) {
        this.bootKiosk();
      }
    },
    hasRosterFilter() {
      if (this.hasRosterFilter) {
        this.store.state.searchGuests = false;
      }
    },
    'store.state.eventToCheckIn'(newEvent) {
      this.store.state.checkIns = [];
      if (newEvent) {
        localStorage.setItem('event_for_checkin', JSON.stringify(newEvent));
        this.loadCheckIns(newEvent);
        // Set rosters
        if (newEvent.id !== -1 && newEvent.roster_events.length > 0) {
          let rosterIds = _.map(newEvent.roster_events, 'roster.id');
          this.store.setRostersToFilter(rosterIds);
        } else {
          this.store.setupSavedFilters();
        }
      } else {
        localStorage.removeItem('event_for_checkin');
      }
    },
    'store.state.searchGuests'(newGuestFilter) {
      localStorage.setItem('search_guests', JSON.stringify(newGuestFilter));
    },
    'store.state.rostersToFilter'(newRostersFilter) {
      // This local storage saving should only apply to events where the roster is not explicitly included.
      // Beause of that we return early so we don't overrwrite the default rosters for this kiosk.
      if (this.eventHasRosters) {
        return;
      }

      if (this.hasRosterFilter) {
        localStorage.setItem('rosters_to_filter', JSON.stringify(newRostersFilter));
        // Temp hack to ensure we stop saving singular roster_to_filter values once we've booted and transitioned that key
        localStorage.removeItem('roster_to_filter');
      } else {
        localStorage.removeItem('rosters_to_filter');
      }
    },
    'store.state.forceUsawOff'(newForceUsawOff) {
      localStorage.setItem('force_usaw_off', JSON.stringify(newForceUsawOff));
    }
  },
  methods: {
    removeRosterFilter(roster) {
      const rosterIds = _.filter(this.store.state.rostersToFilter, (rId) => { return rId !== roster.id });
      this.store.setRostersToFilter(rosterIds);
    },
    addRosterFilter(roster, $event) {
      if ($event.currentTarget.checked) {
        this.store.setRostersToFilter(_.concat([roster.id], this.store.state.rostersToFilter));
      } else {
        this.removeRosterFilter(roster);
      }
    },
    rosterSelected(roster) {
      return _.includes(this.store.state.rostersToFilter, roster.id);
    },
    keydown(e) {
      if (this.debug) {
        console.log('key up fired');
        console.log(e);
        console.log(e.key);
      }
      this.store.state.scan += e.key;

      if (e.keyCode === 13) {
        this.checkInBarcodeId(this.store.state.scan);
        this.store.state.scan = '';
      }
    },
    checkInBarcodeId(scannedValue) {
      const regex = /!(.*)Enter/;
      const exclaimMatch = scannedValue.match(regex);
      const altRegex = /Shift1(.*)Enter/;
      const altMatch = scannedValue.match(altRegex);
      const alt2Regex = /ShiftW(.*)Enter/;
      const alt2Match = scannedValue.match(alt2Regex);
      if (exclaimMatch || altMatch || alt2Match) {
        let scannedMatch = 'unknown'
        if (exclaimMatch) {
          scannedMatch = exclaimMatch[1];
        } else if (altMatch) {
          scannedMatch = altMatch[1];
        } else if (alt2Match) {
          scannedMatch = alt2Match[1];
        }
        if (this.debug) console.log(`Fire check in for ${scannedMatch}`);
        if (this.store.state.lookingUpId) {
          this.$notificationManager.$emit('show-toast', `Please wait, still looking up previously scanned id`, false);
          return;
        }
        this.store.state.lookingUpId = true;

        let url = `${this.$apiService.athleteIdsUrl()}?scanned=${scannedMatch}`;
        let vm = this;
        axios.get(url)
            .then((response) => {
              if (response.data.athlete_identifiers.length > 0) {
                vm.checkScanWithRosterFilter(response.data.athlete_identifiers[0]);
              } else {
                this.$notificationManager.$emit('show-toast', `Unable to find id card - please try again`, false);
              }
              vm.store.state.lookingUpId = false;
            })
            .catch((error) => {
              vm.store.state.lookingUpId = false;
              this.$notificationManager.$emit('show-toast', error.toString(), false);
            });
      } else {
        if (this.debug) console.log(`skipping check in for ${scannedValue}`);
      }
    },
    showCheckInConfirmation(confirmObj) {
      const vm = this;
      swal.fire({
        type: 'warning',
        title: confirmObj.title,
        text: confirmObj.text,
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonClass: 'btn btn-danger m-l-md',
        confirmButtonText: 'Yes, check in anyways',
        cancelButtonClass: 'btn btn-default',
        allowEnterKey: confirmObj.allowEnterKey,
        buttonsStyling: false,
      })
          .then((result) => {
            if (result.value) {
              confirmObj.confirmCallback();
            } else {
              const name = confirmObj.profile.display_name || confirmObj.profile.full_name;
              this.$notificationManager.$emit('show-toast', `Canceled check in for ${name}`, false);
            }

            vm.store.removeConfirmObj(confirmObj);
          });
    },
    checkScanWithRosterFilter(athleteId) {
      let rosterIds = _.flatMap(athleteId.rosters, 'id');
      // We need to double check the scanned wrestler / id actually belongs to this roster the filter is engaged on
      // and alert the user if not.
      // This does not need to happen for searching by name, because the wrestler endpoint simply does not return
      // wrestlers not part of the existing roster filter.
      const rostersAthleteIsPartOf = _.filter(this.selectedRosters, (roster) => {
        return _.includes(rosterIds, roster.id);
      });
      let vm = this;
      if (this.hasRosterFilter && rostersAthleteIsPartOf.length === 0) {
        this.store.setWrestlerCheckInToConfirm(athleteId.profile,`${athleteId.profile.display_name} is not part of ${this.rosterFilterName}!`, 'Do you still want to check them in? If this roster is based off of membership or registration, their payments are not in good standing.',
            false, () => {
              vm.processCheckIn(athleteId.profile);
            });
        // Then fire the alert for it
        this.showCheckInConfirmation(_.last(this.store.state.wrestlersToConfirm));
      } else {
        this.processCheckIn(athleteId.profile);
      }
    },
    bootKiosk() {
      const vm = this;
      console.log('booting kiosk called');
      Promise.all([this.loadEvents(), this.loadAllRegistrationQuestions(), this.loadRosters(), this.loadTeam()])
          .then((results) => {
            // Update the state with these objects returned from the API
            const events = results[0];
            vm.store.setUpcomingEvents(events);

            const questions = results[1];
            vm.store.setCheckInQuestions(questions);

            // Set up the filters from localstorage if needed for guests
            vm.store.state.searchGuests = JSON.parse(localStorage.getItem('search_guests'));
            // Another localstorage trick for supressing usaw
            vm.store.state.forceUsawOff = JSON.parse(localStorage.getItem('force_usaw_off')) || false;

            // Set up the rosters
            const rosters = results[2];
            vm.store.setRosters(rosters);

            // Make sure we have up to date team information (USAW settings + branding)
            const team = results[3];
            vm.store.setTeamInfo(team);

            vm.booting = false;
          })
          .catch((error) => {
            console.log(error);
            vm.bootError = true;
          });
    },
    eventName(event) {
      let start = '';
      if (!event.all_day) {
        start = `- ${moment(event.start_at)
            .format('MMM D, hh:mm a')}`;
      }
      return `${event.name} ${start}`;
    },
    eventTime(event) {
      let start = event.all_day ? moment(event.start_at.split('T')[0]) : moment(event.start_at);
      // Subtract one day for 'human speak' UI display in the end at ambigious case
      let end = event.all_day ? moment(event.end_at.split('T')[0])
          .subtract(1, 'day') : moment(event.end_at);

      // We have a multi day spanning, all day event
      if (event.all_day && end.diff(start, 'days') >= 1) {
        return `all day ${start.format('M/D/YYYY')} to ${end.format('M/D/YYYY')}`;
      } else if (event.all_day) {
        return start.format('M/D/YYYY');
      } else {
        return `${start.format('M/D/YYYY hh:mm a')} to ${end.format('hh:mm a')}`;
      }
    },
    loadTeam() {
      return axios.get(this.$apiService.teamUrl(this.store.state.kiosk.team.id))
          .then((response) => Promise.resolve(response.data))
          .catch((error) => Promise.reject(error));
    },
    loadAllRegistrationQuestions() {
      const vm = this;
      const url = vm.$apiService.registrationQuestions('CheckIn');

      return vm.$apiService.loadAllPages(url, 'registration_questions')
          .then((objects) => Promise.resolve(objects))
          .catch((error) => Promise.reject(error));
    },
    loadEvents() {
      const start = moment()
          .startOf('day')
          .toISOString();
      const end = moment()
          .endOf('day')
          .toISOString();
      const params = `?&start=${start}&end=${end}&preventCache=${new Date()}`;
      const url = this.$apiService.eventsUrl(params);
      return this.$apiService.loadAllPages(url, 'events')
          .then((events) => Promise.resolve(events))
          .catch((error) => Promise.reject(error));
    },
    loadCheckIns(forEvent) {
      let vm = this;

      if (forEvent && forEvent.id !== -1) {
        // Load all the checkins for a specific event
        vm.store.state.loadingCheckIns = true;
        return this.$apiService.loadAllPages(this.$apiService.eventCheckInsUrl(forEvent.id, 'present'), 'check_ins')
            .then((check_ins) => {
              vm.store.setCheckIns(check_ins);
              vm.store.state.loadingCheckIns = false;
            })
            .catch((error) => console.error(error));
      } else {
        // If we are not looking for a specific event, don't load anything up.
        const tz = moment.tz.guess();
        const today = moment().tz(tz)
        vm.store.state.loadingCheckIns = true;
        return this.$apiService.loadAllPages(this.$apiService.genericCheckInsUrl(today), 'check_ins')
            .then((check_ins) => {
              vm.store.setCheckIns(check_ins);
              vm.store.state.loadingCheckIns = false;
            })
            .catch((error) => console.error(error));
      }

    },
    loadRosters() {
      const url = this.$apiService.rostersUrl();
      return this.$apiService.loadAllPages(url, 'rosters')
          .then((rosters) => Promise.resolve(rosters))
          .catch((error) => Promise.reject(error));
    },
    missingUsawMembership(wrestler) {
      if (!wrestler.usaw_membership_id || !wrestler.usaw_expires_on) {
        return true;
      }

      return moment(wrestler.usaw_expires_on)
          .isBefore(moment());
    },
    processCheckIn(wrestler) {
      // Hide the list of wrestlers again in the UI
      this.$notificationManager.$emit('profile-clicked');
      if (this.requiresUsaw && this.missingUsawMembership(wrestler)) {
        const name = wrestler.display_name || wrestler.full_name;
        let vm = this;
        this.store.setWrestlerCheckInToConfirm(wrestler,`${name} does not have an active USAW card!`, 'Do you still want to check them in? Their USA Wrestling card is expired, not purchased, or has not been entered into WrestlingIQ.',
            false, () => {
              vm.postCheckIn(wrestler);
            });
        // Then fire the alert for it
        this.showCheckInConfirmation(_.last(this.store.state.wrestlersToConfirm));
      } else {
        this.postCheckIn(wrestler);
      }
    },
    postCheckIn(wrestler) {
      // Fire off a check in for this wrestler
      const params = {
        check_in: {
          wrestler_profile_id: wrestler.id,
          status: 'present',
        },
      };
      // Thread in the event if we are doing that
      const eventId = _.get(this.store, 'state.eventToCheckIn.id', null);
      if (eventId && eventId !== -1) {
        params.event_id = eventId;
      }
      const vm = this;
      const url = vm.$apiService.checkInUrl();
      axios.post(url, params)
          .then((response) => {
            vm.store.addToCheckIns(response.data, this.$notificationManager);
          })
          .catch((error) => {
            console.error(error);
            // todo error handling
          });
    },
    wrestlersUrl() {
      return this.$apiService.wrestlersUrl();
    },
    setSearching(newSearching) {
      this.searching = newSearching;
    },
    logOut() {
      this.store.setKiosk(null);
    },
  },
};
</script>
