<template>
  <div>
    <div v-if="showCustomQuestions" class="m-t-m">
      <div>
        <h2>
          More info needed to finish checking in {{ checkIn.profile.display_name }}
        </h2>
        <hr class="m-t-none"/>
      </div>
      <div class="m-r-n-sm m-l-n-sm xs-m-l-none xs-m-r-none">
        <registration-info-form
            profile-type="WrestlerProfile"
            :profile-id="checkIn.wrestler_profile_id"
            :check-in-id="checkIn.id"
            :specific-questions="checkInQuestions"
            :endpoint="endpoint"
            :access-key="accessKey"
            :cloudfront-base-url="cloudfrontBaseUrl"
            :respondant-id="this.store.state.kiosk.id"
            respondant-type="Kiosk"
        ></registration-info-form>
      </div>
    </div>
    <div v-else>
      <h2 class="m-t-m">
        Additional info
      </h2>
      <hr class="m-t-none"/>
      <p>
        <small>No check in selected</small>
      </p>
    </div>
  </div>
</template>
<script>
import RegistrationInfoForm from '../shared/registration_info_form.vue';

export default {
  name: 'kiosk-form',
  components: { RegistrationInfoForm },
  // This component does not receive a check in as a prop
  // because it is an async component. Meaning,
  // if a check in happens, but you are still filling this out,
  // we do not want it to re-render.
  props: {
    checkInQuestions: {
      type: Array,
      default() {
        return [];
      },
    },
    endpoint: {
      type: String,
      required: false,
    },
    accessKey: {
      type: String,
      required: false,
    },
    cloudfrontBaseUrl: {
      type: String,
      required: false,
    },
    store: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    showCustomQuestions() {
      return this.checkIn && this.checkInQuestions.length > 0;
    },
    checkIn() {
      return this.store.state.editingCheckIn;
    },
  },
  created() {
    this.$notificationManager.$on('registration-info-saved', this.questionsSaved);
  },
  destroyed() {
    this.$notificationManager.$off('registration-info-saved', this.questionsSaved);
  },
  methods: {
    questionsSaved(profileId, profileType) {
      if (profileType === 'WrestlerProfile' && profileId === this.checkIn?.wrestler_profile_id) {
        this.store.checkInFinalized(this.checkIn?.id);
      }
    },
  },
};
</script>
