<template>
  <div>
    <div>
      <p v-if="hasCheckIns" class="font-small m-t-xxs m-l-xxs m-b-m">
        <strong>{{ checkIns.length }}</strong> checked in
      </p>
      <div v-if="store.state.loadingCheckIns">
        <div class="sk-spinner sk-spinner-fading-circle">
          <div class="sk-circle1 sk-circle"></div>
          <div class="sk-circle2 sk-circle"></div>
          <div class="sk-circle3 sk-circle"></div>
          <div class="sk-circle4 sk-circle"></div>
          <div class="sk-circle5 sk-circle"></div>
          <div class="sk-circle6 sk-circle"></div>
          <div class="sk-circle7 sk-circle"></div>
          <div class="sk-circle8 sk-circle"></div>
          <div class="sk-circle9 sk-circle"></div>
          <div class="sk-circle10 sk-circle"></div>
          <div class="sk-circle11 sk-circle"></div>
          <div class="sk-circle12 sk-circle"></div>
        </div>
      </div>
      <div v-else>
        <ul v-if="style === 'list'" class="list-unstyled m-b-xs">
          <li
            v-for="checkIn in currentPageResults"
            :class="rowClasses(checkIn)"
            @click="openCheckInEditor(checkIn)"
          >
            <div class="flex align-items-center">
              <div v-if="avatarExists(checkIn)" class="m-r-m">
                <div class="image">
                  <img
                    :src="checkIn.profile.avatar_thumbnail"
                    class="kiosk-profile-pic"
                    alt="profile picture"
                  />
                </div>
              </div>
              <profile-photo-placeholder
                classes="kiosk-profile-pic m-r-m color-gray-400"
                v-else
              ></profile-photo-placeholder>
              <div class="flex-col">
                <h3 class="m-t-none m-b-none">
                  {{ checkIn.profile.display_name }}
                </h3>
                <p class="color-gray-500 m-b-none m-t-xxs">
                  {{ checkInAt(checkIn, false) }}
                  <span v-if="checkIn.class_pass_id">Class Pass Used <span v-if="checkIn.class_pass.paid_session">- {{ checkIn.class_pass.paid_session.name }}</span></span>
                </p>
              </div>
            </div>
            <div class="m-l-auto p-r-sm flex">
              <div v-if="needsFinalization(checkIn)" class="m-r-lg">
                <span class="color-red-700"><i class="fa fa-warning"></i></span>
              </div>
              <div v-else class="m-r-lg">
                <span class="color-green-400"><i class="fa fa-check"></i></span>
              </div>
              <div>
                <i
                  v-if="beingEdited(checkIn)"
                  class="fa fa-angle-down fa-lg"
                ></i>
                <i v-else class="fa fa-angle-right fa-lg"></i>
              </div>
            </div>
          </li>
        </ul>
        <div class="grid grid-cols-3 gap-4 m-b-xs" v-else>
          <div
            v-for="checkIn in currentPageResults"
            class="cursor-pointer flex-col hover-opacity-75"
            :class="gridClasses(checkIn)"
            @click="openCheckInEditor(checkIn)"
          >
            <div v-if="avatarExists(checkIn)" class="">
              <img
                :src="checkIn.profile.avatar_thumbnail"
                class="kiosk-grid-pic"
                alt="profile picture"
              />
            </div>
            <profile-photo-placeholder
              classes="kiosk-grid-pic color-gray-400"
              v-else
            ></profile-photo-placeholder>
            <h3 class="m-t-sm m-b-none">
              {{ checkIn.profile.display_name }}
            </h3>
            <div class="flex flex-wrap gap-x-xxs gap-y-xxs items-center m-t-xs">
            <tag
              v-for="tag in rosterTagsWithCount(checkIn.profile)"
              :tag="tag"
              :key="tag.abbreviation"
              :count="tag.count"
            ></tag>
            </div>
            <div v-if="checkIn.class_pass_id && checkIn.class_pass.paid_session">
              <p class="m-b-none m-t-xxs">
                <span class="label label-primary">{{ checkIn.class_pass.paid_session.name }}</span>
              </p>
            </div>
            <div class="flex align-items-center justify-space-between">
              <p class="color-gray-500 m-b-none m-t-xxs">
                {{ checkInAt(checkIn, false) }}
              </p>
              <div class="flex m-r-xs">
                <div v-if="needsFinalization(checkIn)">
                  <span class="color-red-700"
                    ><i class="fa fa-warning"></i
                  ></span>
                </div>
                <div v-else>
                  <span class="color-green-400"
                    ><i class="fa fa-check"></i
                  ></span>
                </div>
                <div>
                  <i
                    v-if="beingEdited(checkIn)"
                    class="fa fa-angle-down fa-lg m-l-xxs"
                  ></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="flex justify-space-between align-items-center"
      :class="{ 'border-top': hasCheckIns, 'p-t-sm': hasCheckIns }"
    >
      <div>
        <nav
          aria-label="Check-in pagination navigation"
          class="flex-col flex align-items-center"
          v-if="hasCheckIns"
        >
          <ul
            v-show="!showAll"
            class="pagination pagination-sm m-b-none m-t-sm"
          >
            <li>
              <a @click.prevent="decrementPage" aria-label="Previous">
                <span aria-hidden="true">&laquo;</span>
              </a>
            </li>
            <li
              v-for="page in pages"
              :class="page === currentPage + 1 ? 'active' : ''"
            >
              <a @click.prevent="showPage(page - 1)">{{ page }}</a>
            </li>
            <li>
              <a @click.prevent="incrementPage" aria-label="Next">
                <span aria-hidden="true">&raquo;</span>
              </a>
            </li>
          </ul>
          <a
            class="font-small text-underline m-t-xxs"
            @click="showAll = !showAll"
            v-if="store.state.eventToCheckIn"
          >
            {{ showAll ? "Minimize" : "Show all" }}
          </a>
        </nav>
        <p v-else>
          <small v-if="!store.state.loadingCheckIns"
            >No check ins recorded
            <span
              v-if="
                store.state.eventToCheckIn &&
                store.state.eventToCheckIn.id !== -1
              "
              >for {{ store.state.eventToCheckIn.name }}</span
            ></small
          >
        </p>
      </div>
      <div class="flex m-l-sm">
        <div>
          <i
            class="fa fa-th cursor-pointer"
            :class="{
              'color-blue-500': gridStyle,
              'color-gray-400': listStyle,
            }"
            @click="style = 'grid'"
            title="Grid"
          ></i>
        </div>
        <div class="m-l-xs">
          <i
            class="fa fa-list cursor-pointer"
            :class="{
              'color-blue-500': listStyle,
              'color-gray-300': gridStyle,
            }"
            @click="style = 'list'"
            title="List"
          ></i>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ProfilePhotoPlaceholder from "./profile-photo-placeholder.vue";
import Tag from "../shared/tag.vue";
import { taggableMix } from '../shared/taggable_mix';
export default {
  name: "paginated-check-ins",
  components: { Tag, ProfilePhotoPlaceholder },
  mixins: [taggableMix],
  props: {
    checkIns: {
      type: Array,
      default() {
        return [];
      },
    },
    events: {
      type: Array,
      default() {
        return [];
      },
    },
    store: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      currentPage: 0,
      pageSize: 3,
      style: "grid", // can also be 'list',
      showAll: false,
    };
  },
  computed: {
    gridStyle() {
      return this.style === "grid";
    },
    listStyle() {
      return this.style === "list";
    },
    hasCheckIns() {
      return this.checkIns.length > 0;
    },
    pages() {
      if (this.checkIns.length < this.pageSize) {
        return 1;
      }

      return Math.ceil(this.checkIns.length / this.pageSize);
    },
    currentPageResults() {
      // Short circuit in case we are hiding pagination
      if (this.showAll) {
        return this.checkIns;
      }

      return this.checkIns.slice(
        this.currentPage * this.pageSize,
        (this.currentPage + 1) * this.pageSize
      );
    },
  },
  methods: {
    avatarExists: function (checkIn) {
      return checkIn.profile?.avatar_thumbnail;
    },
    openCheckInEditor(checkIn) {
      // Don't love this, since it's reaching into the parents global scope, but it's the chepaest way to not open editors if we don't have questions to edit
      if (this.store.state.checkInQuestions.length > 0) {
        this.store.setEditingCheckIn(checkIn);
      }
    },
    needsFinalization(checkIn) {
      return _.find(
        this.store.state.checkInsToFinalize,
        (ch) => ch.id === checkIn.id
      );
    },
    beingEdited(checkIn) {
      let beingEdited = false;
      if (this.store.state.editingCheckIn) {
        beingEdited = checkIn.id === this.store.state.editingCheckIn.id;
      }
      return beingEdited;
    },
    gridClasses(checkIn) {
      return {
        "bg-gray-50": this.beingEdited(checkIn),
        "p-xs": this.beingEdited(checkIn),
      };
    },
    rowClasses(checkIn) {
      return {
        "bg-gray-50": this.beingEdited(checkIn),
        flex: true,
        "m-t-sm": true,
        "p-xs": this.beingEdited(checkIn),
        "rounded-xxs": true,
        "align-items-center": true,
        "cursor-pointer": true,
        "hover-opacity-75": true,
      };
    },
    eventFor(checkIn) {
      const vm = this;
      return _.find(vm.events, {
        id: checkIn.event_id,
      });
    },
    checkInAt(checkIn, showEventName) {
      const time = moment(checkIn.created_at).format("hh:mm a");
      const event = this.eventFor(checkIn);
      const eName = event && showEventName ? `${event.name} ` : "";
      return `${eName}${time}`;
    },
    // This method handles indexing to 0
    showPage(page) {
      this.currentPage = page;
    },
    incrementPage() {
      // Make sure we do not go beyond our last index
      if (this.currentPage + 1 === this.pages) {
        return;
      }

      this.currentPage = this.currentPage + 1;
    },
    decrementPage() {
      // Make sure we do not go beyond our first, 0 indexed, page
      if (this.currentPage === 0) {
        return;
      }

      this.currentPage = this.currentPage - 1;
    },
  },
};
</script>
