<template>
  <div class="search-wrestler teammate">
    <div v-if="!noHr" class="hr-line-dashed" style="margin: 0"></div>
    <div class="row">
      <div class="col-xs-12">
        <div class="flex p-t-sm p-b-sm">
          <div v-if="avatarExists" class="m-r-m">
            <div class="image">
              <img
                :src="wrestler.avatar_thumbnail"
                class="kiosk-profile-pic"
                alt="profile picture"
              />
            </div>
          </div>
          <div>
            <div class="flex flex-wrap gap-x-sm align-items-center">
                <h3 class="m-b-none">{{ wrestler.first_name }} {{ wrestler.last_name }}</h3>
                <div v-if="rosterTagsWithCount(wrestler).length > 0" class="flex flex-wrap gap-x-xxs gap-y-xxs items-center">
                    <tag
                            v-for="tag in rosterTagsWithCount(wrestler)"
                            :tag="tag"
                            :key="tag.abbreviation"
                            :count="tag.count"
                    ></tag>
                </div>
            </div>
            <p class="search-type-label" v-html="">
              {{ wrestler.weight_class }} lbs
            </p>
          </div>
          <div class="m-l-auto flex-shrink-0">
            <button
              class="btn btn-primary"
              @click="$emit('check-in', wrestler)"
            >
              Check in
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Tag from "./tag.vue";
import { taggableMix } from './taggable_mix';

export default {
  name: "wrestler-kiosk-wrestler",
  components: { Tag },
  mixins: [taggableMix],
  props: ["wrestler", "noHr"],
  computed: {
    avatarExists: function () {
      return this.wrestler?.avatar_thumbnail;
    },
  },
  methods: {},
};
</script>
