<template>
  <div class="row m-t-lg">
    <div class="col-xs-12 col-sm-6 col-sm-offset-3 col-md-4 col-md-offset-4">
      <div class="ibox">
        <div class="ibox-content p-m text-center">
          <h1>WrestlingIQ Kiosk</h1>
          <p class="m-t-m">
            Please enter your kiosk device code below.<br/>Need a kiosk code? Email support@wrestlingiq.com
          </p>
          <div class="form-group p-x-lg m-t-lg">
            <input v-model="kioskCode"
                   class="form-control"
                   placeholder="6-digit Kiosk Code"
                   required="required"
                   type="text"
                   name="kiosk-code"
                   autocapitalize="off"
                   autocomplete="off"
                   autocorrect="off"
                   spellcheck="false"
            >
            <ladda-button @lbClicked="authenticate" el-class="btn btn-primary m-t-sm btn-lg" :loading="loading">
              Use Kiosk
            </ladda-button>
            <p class="text-danger m-t-sm" v-if="error">
              {{ errorMessage }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LaddaButton from '../shared/ladda_button.vue';

export default {
  name: 'device-setup',
  components: { LaddaButton },
  props: {
    store: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      error: false,
      errorMessage: false,
      kioskCode: '',
    };
  },
  methods: {
    authenticate() {
      const vm = this;
      vm.loading = true;
      vm.error = false;
      const url = vm.$apiService.kioskSessionUrl();
      const params = {
        kiosk: {
          token: vm.kioskCode,
        },
      };
      axios.post(url, params)
        .then((response) => {
          vm.store.setKiosk(response.data.kiosk);
          vm.loading = false;
        })
        .catch((error) => {
          vm.loading = false;
          vm.errorMessage = 'Failed to setup kiosk. Please double check your Kiosk code or contact support@wrestlingiq.com';
          vm.error = true;
        });
    },
  },
};
</script>
