import Vue from 'vue/dist/vue.esm';

import VTooltip from 'v-tooltip';
import axios from 'axios';
import axiosSetup from './config/axios_setup';
import TeamApiService from '../shared/team_api_service';
import toastr from 'toastr/build/toastr.min.js';
import AccessibilityHelper from '../shared/accessibility_helper';

// Import the app
import KioskApp from './kiosk-app.vue';

import { initSentry } from '../shared/sentry_config';

// Initialize Sentry
initSentry(Vue);

// Load the app
document.addEventListener('DOMContentLoaded', () => {
  // Setup axios
  axiosSetup();

  // Setup tooltips
  Vue.use(VTooltip, {
    themes: {
      'info-tooltip': {
        $extend: 'tooltip',
        triggers: ['hover', 'focus', 'click'],
        popperTriggers: ['hover', 'focus', 'click'],
        // Other options (see the 'Global options' section)
      },
    },
  });

  const api = new TeamApiService();
  const notificationManager = new Vue();
  const a11yHelper = new AccessibilityHelper();

  // I should move this key stuff into the store
  Object.defineProperties(Vue.prototype, {
    $apiService: {
      get() {
        return api;
      },
    },
    $srHelper: {
      get() {
        return a11yHelper;
      },
    },
    // We need to stub this, because the search component uses it to emit things
    // and we share that component with the logged in app for now
    $notificationManager: {
      get() {
        return notificationManager;
      },
    },
  });

  const app = new Vue({
    el: '#kiosk-vue-wrapper',
    components: {
      KioskApp,
    },
    mounted() {
      // Setup global toastr options
      toastr.options = {
        closeButton: false,
        debug: false,
        progressBar: false,
        preventDuplicates: false,
        positionClass: 'toast-top-right',
        showDuration: 400,
        hideDuration: 700,
        timeOut: 5000,
        extendedTimeOut: 10000,
        showEasing: 'swing',
        hideEasing: 'linear',
        showMethod: 'fadeIn',
        hideMethod: 'fadeOut',
      };

      this.$notificationManager.$on('show-toast', this.showToast);
    },
    destroyed() {
      this.$notificationManager.$off('show-toast', this.showToast);
    },
    methods: {
      showToast(message, success) {
        if (success) {
          toastr.success(message);
          return;
        }

        toastr.error(message);
      },
    }
  });
});
